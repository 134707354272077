<template>
  <section class="tw-group/item tw-relative tw-inline-block tw-text-left" ref="dropdownButton">
    <div
      class="tw-flex tw-justify-center tw-items-center tw-rounded tw-w-[30px] tw-h-[30px] tw-cursor-pointer"
      :class="[props.isLight ? 'hover:tw-bg-white/[.2]' : 'hover:tw-bg-black/[.03]']"
    >
      <a class="tw-rounded-full tw-overflow-hidden" @click="visible = !visible">
        <NuxtImg
          :src="`/res/country/${currenLang.key}.png`"
          :alt="currenLang.name"
          :title="currenLang.name"
          class="tw-w-[24px] tw-h-[24px]"
        />
      </a>
    </div>
    <div
      class="tw-absolute tw-right-0 tw-z-[9999] tw-mt-2 tw-origin-top-right tw-bg-white tw-rounded-xl tw-px-2 tw-py-3 tw-border tw-border-[#C5AB80] tw-shadow-md tw-transition tw-ease-in-out tw-delay-150"
      :class="[visible ? ' tw-visible tw-opacity-100 tw-scale-100' : ' tw-invisible tw-opacity-0 tw-scale-95']"
    >
      <div class="tw-flex tw-flex-col tw-gap-[6px]">
        <NuxtLink
          :to="switchLocalePath(lang.key)"
          class="tw-rounded-md tw-py-4 tw-w-52 hover:tw-bg-[#C5AB80] hover:tw-text-white hover:tw-font-bold tw-cursor-pointer tw-flex"
          :class="[currenLang.key === lang.key ? 'tw-bg-[#C5AB80] tw-text-white tw-font-bold' : 'tw-bg-[#FAFAFB] tw-text-black']"
          v-for="lang in langs"
          @click="visible = false"
        >
          <span class="tw-border tw-rounded-full tw-mx-2 tw-overflow-hidden">
            <NuxtImg :src="`/res/country/${lang.key}.png`" :alt="lang.name" :title="lang.name" class="tw-w-[22px] tw-h-[22px]" />
            <!-- <span :alt="lang.name" :title="lang.name" :class="`fi fi-${lang.flag}`" class="tw-w-[22px] tw-h-[22px]"></span> -->
          </span>
          {{ lang.name }}
        </NuxtLink>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
  import { LANGUAGES } from '@/config'
  const { locale } = useI18n()

  const props = defineProps({
    isLight: Boolean,
  })

  const switchLocalePath = useSwitchLocalePath()

  const visible = ref(false)
  const dropdownButton = ref()

  const langs = Object.values(LANGUAGES).filter((item) => !!item.visible)

  const currenLang = computed(() =>
    LANGUAGES[locale.value as keyof ILanguages] ? LANGUAGES[locale.value as keyof ILanguages] : LANGUAGES['en'],
  )

  const onClose = (event: MouseEvent) => {
    if (dropdownButton.value) {
      if (!dropdownButton.value.contains(event.target)) {
        visible.value = false
      }
    }
  }

  onMounted(() => {
    window.addEventListener('click', onClose)
  })
  onUnmounted(() => {
    window.removeEventListener('click', onClose)
  })
</script>
